<template>
    <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Meeting summary <strong>saved</strong> successfully.
    </v-alert>
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card
        icon="mdi-clipboard-edit-outline"
        title="Meeting Summary"
        class="px-5 py-3">
            <v-container class="px-5 py-3">
                <v-row>
                
                <!-- <v-col
                    cols="12"
                    md="6"
                >
                    <v-text-field
                    label="Homeowner(s)"
                    disabled
                    />
                </v-col>

                <v-col
                    cols="12"
                    md="6"
                >
                    <v-text-field
                    label="Address"
                    disabled
                    />
                </v-col> -->

                <v-col
                cols="12"
                md="6"
                >
                    <v-checkbox 
                    label="House Drawing back for Revisions?"
                    v-model="meeting_summary.HouseDrawingRevisionsFlag">
                    </v-checkbox>
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-textarea
                    label="House Drawing back for Revisions:Notes"
                    counter="1000"
                    :rules=descriptionRules
                    v-model="meeting_summary.HouseDrawingRevisionsNotes"
                    />
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-textarea
                    label="Follow-up Questions"
                    counter="1000"
                    :rules=descriptionRules
                    v-model="meeting_summary.FollowupQuestions"
                    />
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-textarea
                    label="Additions to be added to Page 2 price sheet"
                    counter="1000"
                    :rules=descriptionRules
                    v-model="meeting_summary.AdditionsToPage2"
                    />
                </v-col>
                <v-col
                    cols="12"
                    class="text-right"
                >
                    <v-btn
                    color="success"
                    class="mr-0"
                    @click="updateMeetingSummary()"
                    >
                    Save
                    </v-btn>
                </v-col>
                </v-row>
            </v-container>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
  export default {

    name:'StaffMeetingNotes',
    data () {
        return {
            home_id: localStorage.getItem("homeid"),
            alert:false,
            descriptionRules: [
                v => (v.length <= 1000) || 'Max 1000 characters.',
            ],
            meeting_summary:{
                HouseDrawingRevisionsFlag:false,
                HouseDrawingRevisionsNotes:'',
                FollowupQuestions:'',
                AdditionsToPage2:''
            }
        }
    },

    created () {
      this.getMeetingSummary()
    },

    methods: {

      updateMeetingSummary() {
        
        this.alert = true

        var body ={
          'home_id':this.home_id,
          "drawing_for_revisions":this.meeting_summary.HouseDrawingRevisionsFlag,
          "drawing_revisions_notes":this.meeting_summary.HouseDrawingRevisionsNotes,
          "follow_up_questions":this.meeting_summary.FollowupQuestions,
          "additions_to_pricesheet":this.meeting_summary.AdditionsToPage2
        }       

        axios
        .post(process.env.VUE_APP_UPDATE_MEETING_SUMMARY_URL,body)
        .then(() => {
        })
        .catch(error => {
          console.log(error)
        })
      },

      getMeetingSummary () {
        
        axios
        .get(process.env.VUE_APP_GET_MEETINGSUMMARY_URL,
        {
          params: {
            home_id: this.home_id
          }
        })
        .then(response => {
            
            if (response.data)
            {
                this.meeting_summary = response.data
            }
        })
        .catch(error => {
          console.log(error)
        })

      },

    },

  }
</script>